import { Something_Went_Wrong } from '../../utils/constants';
import { filterObject } from '../../utils/filterObject';

import {
  UserState,
  UserActionTypes,
  GET_USER_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  SET_PROFILE_PICTURE_REQUEST,
  SET_USER_FAIL,
  SET_USER_REQUEST,
  SET_USER_SUCCESS,
  SET_PROFILE_PICTURE_SUCCESS,
  SET_PROFILE_PICTURE_FAIL,
  PUSH_TO_RECENTLY_VIEWED,
  BirthDate,
} from '../types/user';

const initState: UserState = {
  error: '',
  uid: '',
  email: '',
  userName: '',
  firstName: '',
  websiteLink: '',
  middleName: '',
  lastName: '',
  gender: '',
  state: '',
  city: '',
  phoneNumber: '',
  ssn: '',
  registeredSince: null,
  completionRate: 0,
  defaultBillingAddress: '',
  defaultShippingAddress: '',
  formatedDob: null,
  dob: null,
  bankAccount: null,
  taxRate: 0,
  followersCount: 0,
  itemsSelling: 0,
  paymentType: -1,
  itemsSold: 0,
  itemsDraft: 0,
  profilePicture: {
    url: '',
    error: '',
  },
  wishList: {
    list: [],
    error: '',
  },
  recentlyViewed: [],
  sellerReviewIndex: null,
  userType: 'buyer',
  shop: null,
  firstOne: true,
  birthDate: null,
  isDisabled: false,
  sellerData: null,
};

const userReducer = (state = initState, action: UserActionTypes): UserState => {
  if (action.type === PUSH_TO_RECENTLY_VIEWED) {
    const { id } = action.payload;
    return {
      ...state,
      recentlyViewed: [
        ...state.recentlyViewed.filter(oldId => oldId !== id),
        id,
      ],
    };
  } else if (action.type === GET_USER_REQUEST) {
    return {
      ...state,
      error: '',
    };
  } else if (action.type === GET_USER_SUCCESS) {
    const { profilePicture, wishList, dob, ...rest } = action.payload;
    return {
      ...state,
      dob: dob as any,
      //@ts-ignore
      formatedDob: dob != null ? new BirthDate(dob.toDate()) : null,
      error: '',
      profilePicture: {
        error: '',
        url: profilePicture ?? '',
      },
      wishList: {
        list: wishList ?? [],
        error: '',
      },
      ...rest,
    };
  } else if (action.type === GET_USER_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error: error || Something_Went_Wrong,
    };
  } else if (action.type === SET_USER_REQUEST) {
    return {
      ...state,
      error: '',
    };
  } else if (action.type === SET_USER_SUCCESS) {
    const values = filterObject(action.payload); // to remove undefined values from the object, usefull for change user data
    return {
      ...state,
      error: '',
      ...values,
    };
  } else if (action.type === SET_USER_FAIL) {
    const { error } = action.payload;

    return {
      ...state,
      error: error || Something_Went_Wrong,
    };
  } else if (action.type === SET_PROFILE_PICTURE_REQUEST) {
    return {
      ...state,
      profilePicture: {
        ...state.profilePicture,
        error: '',
      },
    };
  } else if (action.type === SET_PROFILE_PICTURE_SUCCESS) {
    return {
      ...state,
      profilePicture: {
        ...action.payload,
        error: '',
      },
    };
  } else if (action.type === SET_PROFILE_PICTURE_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      profilePicture: {
        ...state.profilePicture,
        error: error || Something_Went_Wrong,
      },
    };
  }
  // done in another reducers
  // else if (action.type === ADD_WISHLIST_ITEM_REQUEST) {
  //   return {
  //     ...state,
  //     wishList: {
  //       ...state.wishList,
  //       error: '',
  //     },
  //   };
  // } else if (action.type === ADD_WISHLIST_ITEM_SUCCESS) {
  //   const newWishlist = state.wishList.list.filter(
  //     item => item !== action.payload.id,
  //   );
  //   newWishlist.push(action.payload.id);

  //   return {
  //     ...state,
  //     wishList: {
  //       ...state.wishList,
  //       list: newWishlist,
  //       error: '',
  //     },
  //   };
  // } else if (action.type === ADD_WISHLIST_ITEM_FAIL) {
  //   return {
  //     ...state,
  //     wishList: {
  //       ...state.wishList,
  //       error: action.payload.error || Something_Went_Wrong,
  //     },
  //   };
  // }
  else return state;
};

export default userReducer;
