import { makeStyles, createStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    root: {},
    ubuntuBoldWhite: {
      fontFamily: 'ubuntu',
      color: '#fff',
    },
    title: {
      position: 'absolute',
      top: '30px',
      left: '40px',
      fontWeight: 'bold',
    },
    coverMidTitle: {
      fontWeight: 'bold',
      fontFamily: 'Poppins'
    },
    midCoverTextWrapper: {
      position: 'absolute',
      top: '30%',
      left: '10%',
      maxWidth: '100%',
      fontFamily: 'Poppins'
    },
    coverWrapper: {
      position: 'relative',
      minHeight: '100vh',
    },
  }),
);
