import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container, createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Helmet } from 'react-helmet';


// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     headerContainer: {
//       height: '100%',
//       width: '100%',
//       [theme.breakpoints.down('xs')]: {
//         height: '20vh',
//       },
//     },
//   }),
// );

const BlogDetailPage = () => {
  // const classes = useStyles();
  // const theme = useTheme();
  const [blogDetails, setBlogDetails] = useState(null);
  const [metaData, setMetaData] = useState(null);


  const { id } = useParams();

  useEffect(() => {
    axios
      .get(
        `https://cms.zetaton.dev/api/Blog-details?filters[blogName][$eq]=${id}`,
      )
      .then(response => {
        let blogData = response.data.data[0];

        if (blogData && blogData.attributes && blogData.attributes.Editor) {
          let modifiedEditor = blogData.attributes.Editor.replace(
            /(\w+ \d{1,2}, \d{4})/g,
            match => {
              return formatDate(match);
            },
          );

          blogData.attributes.Editor = modifiedEditor;
        }

        setBlogDetails(blogData);
        if (blogData && blogData.attributes && blogData.attributes.meta) {
          setMetaData(blogData?.attributes?.meta || null);
        }
      })
      .catch(error => console.error('Error fetching blog details:', error));
  }, [id]);

  console.log(metaData, 'MetaData');

  const formatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${month}/${day}/${year}`;
  };

  
  useEffect(() => {
    if (metaData) {
    
      document.title = metaData.title || 'Antique Tips and Trends Blog | Antiquesmart';
  
      const existingMetaKeywords = document.querySelector('meta[name="keywords"]');
      if (existingMetaKeywords) {
        existingMetaKeywords.setAttribute('content', metaData.keywords);
      } else {
        const metaKeywords = document.createElement('meta');
        metaKeywords.setAttribute('name', 'keywords');
        metaKeywords.setAttribute('content', metaData.keywords);
        document.head.appendChild(metaKeywords);
      }
      
      const existingMetaDescription = document.querySelector('meta[name="description"]');
      if (existingMetaDescription) {
        existingMetaDescription.setAttribute('content', metaData?.jsonLdSchema?.description);
      } else {
        const metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.setAttribute('content', metaData?.jsonLdSchema?.description);
        document.head.appendChild(metaDescription);
      }
  
      // og:title
      const existingOgTitle = document.querySelector('meta[property="og:title"]');
      if (existingOgTitle) {
        existingOgTitle.setAttribute('content', metaData?.jsonLdSchema?.headline);
      } else {
        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.setAttribute('content', metaData?.jsonLdSchema?.headline);
        document.head.appendChild(ogTitle);
      }
  
      // og:description
      const existingOgDescription = document.querySelector('meta[property="og:description"]');
      if (existingOgDescription) {
        existingOgDescription.setAttribute('content', metaData?.jsonLdSchema?.description);
      } else {
        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.setAttribute('content', metaData?.jsonLdSchema?.description);
        document.head.appendChild(ogDescription);
      }
      
       // og:image
       const existingOgImage = document.querySelector('meta[property="og:image"]');
       if (existingOgImage) {
        existingOgImage.setAttribute('content', metaData?.jsonLdSchema?.image);
       } else {
         const ogImage = document.createElement('meta');
         ogImage.setAttribute('property', 'og:image');
         ogImage.setAttribute('content', metaData?.jsonLdSchema?.image);
         document.head.appendChild(ogImage);
       }
  
   // twitter:title
       const existingTwitterTitle = document.querySelector('meta[name="twitter:title"]');
       if (existingTwitterTitle) {
         existingTwitterTitle.setAttribute('content', metaData.title);
       } else {
         const twitterTitle = document.createElement('meta');
         twitterTitle.setAttribute('name', 'twitter:title');
         twitterTitle.setAttribute('content', metaData.title);
         document.head.appendChild(twitterTitle);
       }
   
       // twitter:description
       const existingTwitterDescription = document.querySelector('meta[name="twitter:description"]');
       if (existingTwitterDescription) {
         existingTwitterDescription.setAttribute('content', metaData?.jsonLdSchema?.description);
       } else {
         const twitterDescription = document.createElement('meta');
         twitterDescription.setAttribute('name', 'twitter:description');
         twitterDescription.setAttribute('content', metaData?.jsonLdSchema?.description);
         document.head.appendChild(twitterDescription);
       }
       
      // json-ld Schema
      const jsonLdScript = document.querySelector('script[type="application/ld+json"]');
      if (jsonLdScript) {
        jsonLdScript.innerHTML = JSON.stringify(metaData.jsonLdSchema);
      } else {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify(metaData.jsonLdSchema);
        document.head.appendChild(script);
      }
    }
  }, [metaData]);
  
  return (
    <>
      
      <div>
        <Container style={{ marginTop: '10px' }}>
          {blogDetails ? (
            // <p>osks</p>
            <div
              dangerouslySetInnerHTML={{
                __html: blogDetails.attributes?.Editor,
              }}
            />
          ) : (
            <p>Loading...</p>
          )}
        </Container>
      </div>
    </>
  );
};

export default BlogDetailPage;
