import Address from '../models/[new]address';

export const formatAddress = (address: Address) =>
  `${address?.addressLineOne ? address?.addressLineOne : ''} ${
    // address?.addressLineTwo ? address?.addressLineTwo : ''
    address?.suite && address?.suite 
      ? address?.suite
      : ''
  } ${address?.city ? address?.city + ',' : ''} ${address?.state} ${
    address?.zipCode
  }`;
