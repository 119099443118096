import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    muiGridLimit3: {
      padding: '12px',
    },
    rootGrid: {
      minHeight: '100vh',
    },
    formGrid: {
      height: 'fit-content',
      padding: '10rem 10rem',
      [theme.breakpoints.down('xl')]: {
        padding: '9rem 8rem',
      },
      [theme.breakpoints.down('lg')]: {
        padding: '6rem 6rem',
      },
      [theme.breakpoints.down('md')]: {
        padding: '6rem 2rem',
      },
     
    },
    formHeader: {
      textAlign: 'center',
      marginBottom: '3.125rem',
    },
    socialLoginWrapper: {
      marginBottom: '1.875rem',
    },
    orText: {
      fontWeight: 'bold',
      color: '#00000061',
    },
    orWrapper: {
      marginBottom: '1.875rem',
    },
    formGridsContainer: {
      marginBottom: '5rem',
    },
    haveAccountText: {
      color: '#00000061',
      width: '100%',
      textAlign: 'center',
    },
  }),
);
