import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  IconButton,
  DialogTitle,
  Dialog,
  DialogContent,
} from '@material-ui/core';
import { RiCloseFill } from 'react-icons/ri';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddedBox: {
      padding: '0 10px',
      [theme.breakpoints.up('md')]: {
        padding: '0 50px',
      },
    },
    customDialog: {
      '& .MuiPaper-rounded': {
        borderRadius: '30px !important',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      gap: '20px',
      paddingBottom: '40px',
      flexDirection: 'row',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        gap: '10px',
      },
    },
    menu: {
      boxShadow: 'none',
      border: '1px solid #E0E0E0',
    },
    menuItem: {
      color: '#42526E',
      fontWeight: 600,
      '&:hover': {
        color: 'red',
        '& .MuiListItemIcon-root': {
          color: 'red',
        },
      },
    },
    icon: {
      color: '#42526E',
      minWidth: '30px',
    },
  }),
);

interface AppDialogProps {
  open: boolean;
  onClose: () => void;
}

const AppDialog: React.FC<AppDialogProps> = ({ open, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      className={classes.customDialog}
    >
      <Box
        style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
      >
        <IconButton
          onClick={onClose}
          style={{
            backgroundColor: '#FFFFFF',
            border: '1px solid #EDEDED',
            borderRadius: '50%',
            padding: '8px',
            color: '#000000',
            marginRight: '8px',
            position: 'absolute',
            top: '8px',
            right: '8px',
          }}
        >
          <RiCloseFill />
        </IconButton>
      </Box>
      <Box className={classes.paddedBox}>
        <DialogTitle>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              variant="h6"
              style={{
                fontWeight: 700,
                marginTop: '50px',
                color: '#1A202C',
                fontSize: '27px',
                textAlign: 'center',
              }}
            >
              Auction Feature Available on Mobile Only
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            style={{
              textAlign: 'center',
              marginBottom: '20px',
              color: '#42526E',
            }}
          >
            This feature is accessible exclusively on our mobile app. For the
            best experience, please download the app to continue.
          </Typography>
          <Box className={classes.buttonContainer}>
            <img
              src={require('../../../assets/google-play.png')}
              alt="Google Play"
              width="143px"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.zetaton.antiquesmart',
                  '_blank',
                )
              }
            />
            <img
              src={require('../../../assets/app-store.png')}
              alt="App Store"
              width="143px"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                window.open(
                  'https://apps.apple.com/us/app/antiquesmart/id1526581604?platform=iphone',
                  '_blank',
                )
              }
            />
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default AppDialog;
