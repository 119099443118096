import React, { useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  Menu,
  Divider,
  Typography,
  Badge,
  Button,
  MenuItem,
  useTheme,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useTypedSelector } from '../../../redux/store';
import ProductImageWithPadge from '../../ProductImageWithPadge';
import priceFormater from '../../../utils/priceFormater';
import {
  deleteWishlistedItemThunk,
  getWishListThunk,
  getWishlitedProductsSelector,
} from '../../../redux/actions/wishlist';
import { addToCartThunk } from '../../../redux/actions/cart';
import AddToCartBtn from '../../buttons/AddToCartBtn';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '50px', // button height is 48px
      maxWidth: 'initial',
      minWidth: '300px !important',
    },
    menuContainer: {
      padding: '1rem !important',
    },
    menuHead: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '300px !important',
      alignItems: 'center',
    },
    title: {
      marginBottom: '1rem',
    },
    headDivider: {
      width: '100%',
      marginBottom: '1rem',
    },
    itemsContainer: {},
    proItem: {
      width: '600px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginBottom: '1rem',
      borderRadius: '4px',
      padding: '0.5rem',
      cursor: 'pointer',
      '&:hover': {
        background: '#ccc',
      },
    },
    itemTitle: {
      width: '163px !important',
    },
    itemPrice: {
      color: '#00000061',
    },
    listBtn: {
      color: '#574146',
      fontWeight: 'bold',
      '&:hover': {
        borderBottom: '2px solid #FDBB84',
      },
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 8,
      top: 7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      color: 'white',
    },
  }),
)(Badge);

interface Props {
  className?: string;
  setMobileMoreAnchorEl: (anchor: null | HTMLElement) => void;
}
const WishlistIconMenu = ({ className, setMobileMoreAnchorEl }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const wishList = useTypedSelector(getWishlitedProductsSelector);
  const uid = useTypedSelector(state => state.user.uid);

  useEffect(() => {
    if (uid) dispatch(getWishListThunk());
  }, [dispatch, uid]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });

  const handleDelete = async (id: string) => {
    dispatch(deleteWishlistedItemThunk({ id }));
  };

  const toProductPage = (slug_title: string) => {
    popupState.close();
    setMobileMoreAnchorEl(null);
    history.push(`/product${slug_title}`);
  };

  const moveToCart = async ({ id, price }: { id: string; price: number }) => {
    dispatch(addToCartThunk({ productId: id, quantity: 1, price }));
  };

  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery('(max-width: 960px)');

  return (
    <>
      {isSmallScreen ? (
        <MenuItem {...bindTrigger(popupState)}>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge badgeContent={wishList?.length ?? ''} color="secondary">
              <FavoriteBorderOutlinedIcon />
            </Badge>
          </IconButton>
          <p>Liked</p>
        </MenuItem>
      ) : (
        <StyledBadge
          {...bindTrigger(popupState)}
          badgeContent={wishList?.length ?? ''}
          color="secondary"
        >
          <IconButton>

          <img
            loading="lazy"
            src={require('../../../assets/landing-page/heart.png')}
            alt="message"
            width="24px"
            height="24px"
          />
          </IconButton>
          {/* <Button className={classes.listBtn}>Liked</Button> */}
        </StyledBadge>
      )}

      <Menu {...bindMenu(popupState)} className={classes.root}>
        <div className={classes.menuContainer}>
          <div className={classes.menuHead}>
            <Typography className={classes.title} component="p" variant="body1">
              Wishlist (
              {wishList?.length === 0
                ? 'No Products added to Liked'
                : wishList?.length}
              )
            </Typography>
          </div>
          <Divider className={classes.headDivider} />
          <div className={classes.itemsContainer}>
            {wishList?.map(pro => (
              <div
                key={pro.id}
                className={classes.proItem}
                onClick={() => toProductPage(pro?.slug_title)}
                style={{
                  width: xsDown ? '348px' : '600px',
                  display: xsDown ? 'block' : 'flex',
                }}
              >
                <ProductImageWithPadge
                  key={pro?.id}
                  id={pro?.id}
                  url={pro?.images[0]}
                  handleDelete={() => handleDelete(pro?.id)}
                />
                <Typography
                  className={classes.itemTitle}
                  component="p"
                  variant="body1"
                  style={{
                    width: xsDown ? 'unset !important' : '163px !important',
                    fontSize: xsDown ? '0.90rem' : '1rem',
                    display: xsDown ? 'inline-flex' : 'block',
                    lineBreak: xsDown ? 'anywhere' : 'unset',
                    marginRight: '10px',
                  }}
                >
                  {`${pro?.title?.slice(0, 20)}${
                    pro?.title?.slice(19) ? '...' : ''
                  }`}
                  <br></br>
                  {pro?.paymentType === 0 && '(Cash only) '}
                </Typography>

                <Typography
                  className={classes.itemPrice}
                  component="p"
                  variant="body1"
                  style={{ display: xsDown ? 'inline-flex' : 'block' }}
                >
                  {pro?.price === -1 || pro?.price === null
                    ? 'Contact for price'
                    : priceFormater.format(pro?.price)}
                </Typography>

                <AddToCartBtn
                  // disabled={
                  //   pro?.paymentType === 0 || pro?.price === -1 ? true : false
                  // }
                  // onClick={() =>
                  //   moveToCart({
                  //     id: pro?.id,
                  //     price: pro?.price,
                  //     // isSold: pro.isSold,
                  //   })
                  // }
                  disabled={
                    pro?.paymentType === 0 || pro?.price === -1 || pro?.price === null
                      ? true
                      : false
                  }
                  onClick={() => {
                    if (typeof pro?.price === 'number') {
                      moveToCart({
                        id: pro?.id,
                        price: pro?.price,
                      });
                    }
                  }}
                  style={{
                    height: xsDown ? '40px' : '48px',
                    fontSize: xsDown ? '0.7rem' : '0.875rem',
                    marginLeft: xsDown ? '175px' : 'unset',
                    marginTop: xsDown ? '-10px' : 'unset',
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </Menu>
    </>
  );
};

export default WishlistIconMenu;
