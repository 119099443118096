import React, { lazy, Suspense } from 'react';
import { Route, RouteProps, Switch, Redirect } from 'react-router-dom';
import { makeStyles, createStyles, useTheme } from '@material-ui/core';
import Login from '../../pages/auth/login';
import useScrollManager from '../../hooks/useScrollManager';
import Community from '../../pages/Community/Community';
import NewNavbar from '../Navbar/NewNavbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import PostPage from '../../pages/Post/PostPage';
import { useTypedSelector } from '../../redux/store';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CircularLoader from '../../components/CircularProgressBar/CircularLoader';
import BlogDetails from '../../pages/home/blogDetails';
import useScrollTop from '../../hooks/useScrollTop';
import ListEvent from '../../pages/events/ListEvent';
import Events from '../../pages/events/Events';
import EventDetails from '../../pages/events/EventDetails';
import SuccessOrder from '../../pages/SuccessOrder';

const Transactions = lazy(
  () => import('../../pages/transactions/transactions'),
);
const ContactUs = lazy(() => import('../../pages/contact-us/contact-us'));
const MyReviews = lazy(() => import('../../pages/my-reviews/my-reviews'));
const Signup = lazy(() => import('../../pages/auth/signup'));
const Footer = lazy(() => import('../footer'));
const Page404 = lazy(() => import('../../pages/Page404'));
const SellerPages = lazy(() => import('../../pages/seller-pages'));
const Following = lazy(() => import('../../pages/following/following'));
const ChatPage = lazy(() => import('../../pages/Chat/NewIndex'));
const PrivacyPolicy = lazy(
  () => import('../../pages/app-services/privacy-policy'),
);
const TermsOfService = lazy(
  () => import('../../pages/app-services/terms-of-service'),
);
const AcceptableUsePolicy = lazy(
  () => import('../../pages/app-services/acceptable-use-policy'),
);
const CookiePolicy = lazy(
  () => import('../../pages/app-services/cookie-policy'),
);
const CategoriesColl = lazy(
  () => import('../../pages/CategoriesCollection/CategoriesColl'),
);
const Eula = lazy(() => import('../../pages/app-services/eula'));
const Disclaimer = lazy(() => import('../../pages/app-services/disclaimer'));
const HomePage = lazy(() => import('../../pages/home'));
const HomePage2 = lazy(() => import('../../pages/home/blogs'));
const ProductPage = lazy(() => import('../../pages/product'));
const SearchPage = lazy(() => import('../../pages/search'));
const ProfileSettingPage = lazy(() => import('../../pages/ProfileSetting'));
const PublicProfile = lazy(() => import('../../pages/PublicProfile'));
const ProfilePayMethodsPage = lazy(() => import('../../pages/profile-payment'));
const CartPage = lazy(() => import('../../pages/CartPage'));
const ChangePasswordPage = lazy(
  () => import('../../pages/auth/ChangePassword'),
);
const OrderHistoryPage = lazy(() => import('../../pages/OrderHistory'));
const ProfileSettingView = lazy(
  () => import('../../pages/ProfileSetting/ProfileSettingView'),
);

export const PublicRoute = ({ children, ...props }: RouteProps) => (
  <Route {...props}>{children}</Route>
);

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const NoAuthRoute = ({ children, ...props }: RouteProps) => {
  const { uid } = useTypedSelector(state => state.auth);

  if (uid) {
    return <Redirect to="/" />;
  } else return <Route {...props}>{children}</Route>;
};

export const PrivateRoute = ({ children, ...props }: RouteProps) => {
  const { uid } = useTypedSelector(state => state.auth);
  const LOGIN = useTypedSelector(state => state.UI.loaders.LOGIN);
  const LOGOUT = useTypedSelector(state => state.UI.loaders.LOGOUT);
  if (LOGIN || LOGOUT) return <CircularLoader.QurtarLoader />; // todo
  if (uid && !LOGIN) {
    return <Route {...props}>{children}</Route>;
  } else return <Redirect to="/login" />;
};

const Routes = () => {
  // useScrollManager();
  useScrollTop();

  return (
    <Suspense fallback={<CircularLoader.QurtarLoader />}>
      <Switch>
        <NoAuthRoute exact path="/login">
          <Login />
        </NoAuthRoute>
        <NoAuthRoute exact path="/signup">
          <Signup />
        </NoAuthRoute>
        <PublicRoute path="*">
          <AuthedRoutes />
        </PublicRoute>
      </Switch>
    </Suspense>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: '100vh',
      height: '100%',
    },
    page404: {
      marginTop: '4rem',
    },
  }),
);
const AuthedRoutes = () => {
  const classes = useStyles();
  // useScrollManager();
  useScrollTop();
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
    
      <NewNavbar />
      <div
        className={classes.root}
        style={{
          minHeight: xsDown ? '32vh' : '57vh',
          marginBottom: xsDown ? '15px' : '15px',
        }}
      >
        <Switch>
          <PublicRoute exact path="/home">
            <HomePage />
          </PublicRoute>
          <PublicRoute exact path="/blogs">
            <HomePage2 />
          </PublicRoute>
          <PublicRoute exact path="/blogs/:id">
            <BlogDetails />
          </PublicRoute>
          <PublicRoute exact path="/">
            <HomePage />
          </PublicRoute>
          <PublicRoute exact path="/product/:slug_title">
            <ProductPage />
          </PublicRoute>
          <PrivateRoute exact path="/profile">
            <ProfileSettingView />
          </PrivateRoute>
          <PrivateRoute exact path="/profile-setting">
            <ProfileSettingPage />
          </PrivateRoute>
          <PublicRoute exact path="/profile/:id">
            <PublicProfile />
          </PublicRoute>
          <PrivateRoute exact path="/payment">
            <ProfilePayMethodsPage />
          </PrivateRoute>
          <PrivateRoute exact path="/change-password">
            <ChangePasswordPage />
          </PrivateRoute>
          <PrivateRoute path="/cart">
            <CartPage />
          </PrivateRoute>
          <PrivateRoute exact path="/order-history/:id?">
            <OrderHistoryPage />
          </PrivateRoute>
          <PrivateRoute exact path="/transactions">
            <Transactions />
          </PrivateRoute>
          <PublicRoute path="/categories">
            <SearchPage />
          </PublicRoute>
          <PrivateRoute exact path="/chat">
            <ChatPage />
          </PrivateRoute>
          <PrivateRoute exact path="/chat/:id">
            <ChatPage />
          </PrivateRoute>
          <PrivateRoute path="/seller">
            <SellerPages />
          </PrivateRoute>
          <PrivateRoute path="/following">
            <Following />
          </PrivateRoute>
          <PrivateRoute path="/my-reviews">
            <MyReviews />
          </PrivateRoute>

          <PrivateRoute path="/list-event/:id?">
            <ListEvent />
          </PrivateRoute>

          <PublicRoute path="/contact-us">
            <ContactUs />
          </PublicRoute>
          <PublicRoute path="/privacy-policy">
            <PrivacyPolicy />
          </PublicRoute>
          <PublicRoute path="/terms-of-service">
            <TermsOfService />
          </PublicRoute>
          <PublicRoute path="/eula">
            <Eula />
          </PublicRoute>
          <PublicRoute path="/cookie-policy">
            <CookiePolicy />
          </PublicRoute>
          <PublicRoute path="/acceptable-use-policy">
            <AcceptableUsePolicy />
          </PublicRoute>
          <PublicRoute path="/disclaimer">
            <Disclaimer />
          </PublicRoute>
          <PublicRoute path="/categories-collection">
            <CategoriesColl />
          </PublicRoute>
          <PublicRoute path="/community">
            <Community />
          </PublicRoute>
          <PublicRoute path="/events">
            <Events />
          </PublicRoute>
          <PublicRoute path="/success-order">
            <SuccessOrder />
          </PublicRoute>
          <PublicRoute path="/event-details/:id?">
            <EventDetails />
          </PublicRoute>
          <PublicRoute exact path="/post/:id">
            <PostPage />
          </PublicRoute>
          <PublicRoute path="*">
            <Page404 className={classes.page404} />
          </PublicRoute>
        </Switch>
      </div>
      <Suspense fallback={<CircularLoader.QurtarLoader />}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Routes;
