// ReusableComponent.js
import React from 'react';
import { Box } from '@material-ui/core';

const StoreButtons = () => {
  return (
    <Box
      style={{
        display: 'flex',
        gap: '8px',
      }}
    >
      <img
        src={require('../../assets/google-play.png')}
        alt="Google Play"
        width="115px"
        style={{ cursor: 'pointer', display: 'block', zIndex: 10 }}
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=com.zetaton.antiquesmart',
            '_blank',
          )
        }
      />
      <img
        src={require('../../assets/app-store.png')}
        alt="App Store"
        width="115px"
        style={{ cursor: 'pointer', display: 'block', zIndex: 10 }}
        onClick={() =>
          window.open(
            'https://apps.apple.com/us/app/antiquesmart/id1526581604?platform=iphone',
            '_blank',
          )
        }
      />
    </Box>
  );
};

export default StoreButtons;
