import React, { useState, useEffect } from 'react';
import { Box, Grid, ThemeProvider, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useDispatch } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import theme from './theme';
import { useHistory, useLocation } from 'react-router-dom';
import AppRoutes from './components/app-routes/app-routes';
import { verfiyAuthThunk } from './redux/actions/auth';
import UsernameDialog from './components/UsernameDialog';
import { useTypedSelector } from './redux/store';
import { auth, usersC, privateUsersC } from './utils/firebase-utils';
import { userConverter, UserType } from './models/[new]user';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { loadStripe } from '@stripe/stripe-js';
import SearchIcon from '@material-ui/icons/Search';
import { Elements } from '@stripe/react-stripe-js';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { STRIPE_KEY } from './utils/constants';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import HomeIcon from '@material-ui/icons/Home';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CategoriesContextProvider from './contexts/CategoriesContext';
import ProductContextProvider from './contexts/ProductsContext';
import { logoutThunk } from './redux/actions/auth';
import { privateUserConverter } from './models/private-user';
import { setUserThunk, getUserThunk } from './redux/actions/user';
import StoreButtons from './components/Breadcrumb/StoreButtons';

function App() {
  const dispatch = useDispatch();
  const userId = useTypedSelector(state => state.auth.uid);
  const { firstOne } = useTypedSelector(state => state.user);
  const [showUsernameDialog, setShowUsernameDialog] = useState(false);
  const stripePromise = loadStripe(STRIPE_KEY);
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [openDisableDialog, setOpenDisableDialog] = useState(false);

  useEffect(() => {
    if (userId) {
      (async () => {
        privateUsersC
          .doc(userId)
          .withConverter(privateUserConverter)
          .onSnapshot(u => {
            if (u?.exists) {
              let userDis = u?.data();
              if (userDis?.isDisabled === true) {
                dispatch(logoutThunk());
                setOpenDisableDialog(true);
              }
            }
          });
      })();
    }
  }, [dispatch, userId]);

  const handleChange = (e: any) => {
    if (e.target.checked === true) {
      dispatch(setUserThunk({ firstOne: true }));
      dispatch(getUserThunk());
    } else {
      dispatch(setUserThunk({ firstOne: false }));
      dispatch(getUserThunk());
    }
    setChecked(!checked);
  };

  useEffect(() => {
    if (
      auth?.currentUser &&
      location?.pathname !== '/terms-of-use' &&
      location?.pathname !== '/privacy-policy' &&
      firstOne === false
    ) {
      setOpen(true);
    }
  }, [firstOne, location]);

  useEffect(() => {
    (async () => {
      if (
        location?.pathname !== '/terms-of-use' &&
        location?.pathname !== '/privacy-policy'
      ) {
        setOpen(true);
      }
      // await dispatch(verfiyAuthThunk());
      if (userId) {
        // console.log(await auth.currentUser?.getIdToken(false));
        const user = (
          await usersC.doc(userId).withConverter(userConverter).get()
        ).data() as UserType;
        if (!user?.userName) {
          setShowUsernameDialog(true);
        }
      } else {
        setShowUsernameDialog(false);
      }
    })();
  }, [dispatch, location, userId]);

  useEffect(() => {
    (async () => {
      // if (!location) {
      await dispatch(verfiyAuthThunk());
      // }
    })();
  }, [userId]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CategoriesContextProvider>
            <ProductContextProvider>
              <SnackbarProvider maxSnack={1}>
                {showUsernameDialog && (
                  <UsernameDialog
                    open={showUsernameDialog}
                    onClose={() => setShowUsernameDialog(false)}
                  />
                )}
                <div>
                  <Dialog
                    maxWidth="sm"
                    fullWidth
                    open={openDisableDialog}
                    onClose={() => setOpen(false)}
                  >
                    <DialogTitle id="id" style={{ padding: '20px' }}>
                      <Typography
                        style={{
                          color: '#663C00',
                          fontSize: '1.5rem',
                          textAlign: 'center',
                        }}
                        component="p"
                        variant="subtitle1"
                      >
                        Your account was disabled by the admin!
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <Grid container direction="row">
                        <Grid item xs={12}>
                          <div
                            style={{
                              width: '100%',
                              paddingBottom: '20px',
                              display: 'flex',
                              justifyContent: 'Center',
                            }}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              onClick={() => {
                                setOpenDisableDialog(false);
                              }}
                              style={{
                                color: 'white',
                                fontSize: '1.33rem',
                                padding: '8px 44px',
                              }}
                            >
                              Ok
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                  {firstOne === false &&
                    location.pathname === '/' &&
                    auth?.currentUser && (
                      <Dialog
                        open={open}
                        aria-labelledby="responsive-dialog-title"
                        onClose={() => setOpen(false)}
                      >
                        <div style={{ padding: '20px' }}>
                          <Grid
                            lg={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '20px',
                            }}
                          >
                            <img
                              loading="lazy"
                              src={require('./assets/newlogo.png')}
                              alt="logo"
                              style={{ width: '220px', height: '140px' }}
                            />
                          </Grid>
                          <Box
                            style={{
                              marginBottom: '20px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <StoreButtons />
                          </Box>
                          <DialogTitle
                            id="responsive-dialog-title"
                            style={{ paddingBottom: '0px' }}
                          >
                            {' Welcome to Antiquesmart.com !'}
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText style={{ fontWeight: 'bold' }}>
                              "The new way to buy and sell old things!" <br />
                              <span>What would you like to do Today ?</span>
                              <br />
                            </DialogContentText>
                          </DialogContent>
                          <div style={{ textAlign: 'center' }}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checked}
                                  onClick={handleChange}
                                  inputProps={{
                                    'aria-label': 'primary checkbox',
                                  }}
                                />
                              }
                              label="Skip Next Time"
                            />
                          </div>
                          <div style={{ borderTop: '1px solid #DDDDDD' }} />
                          <List style={{ marginBottom: '20px' }}>
                            <ListItem
                              button
                              style={{
                                padding: '10px',
                                boxShadow:
                                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                borderRadius: '10px',
                                marginTop: '20px',
                                marginBottom: '25px',
                              }}
                              onClick={() => {
                                history.push('/categories?type=shops');
                                setOpen(false);
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{ display: 'flex' }}
                                >
                                  {' '}
                                  <HomeIcon />
                                  <span
                                    style={{
                                      marginLeft: '2px',
                                      marginTop: '2px',
                                    }}
                                  >
                                    Browse our Vendor Marketplace{' '}
                                  </span>
                                </Typography>
                                <ArrowForwardIosIcon fontSize="medium" />
                              </div>
                              <ListItemText />
                            </ListItem>
                            <ListItem
                              button
                              style={{
                                padding: '10px',
                                boxShadow:
                                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                borderRadius: '10px',
                                marginTop: '20px',
                                marginBottom: '25px',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    display: 'flex',
                                  }}
                                  onClick={() => {
                                    history.push('/categories?text=');
                                    setOpen(false);
                                  }}
                                >
                                  <SearchIcon />{' '}
                                  <span
                                    style={{
                                      marginLeft: '2px',
                                      marginTop: '2px',
                                    }}
                                  >
                                    Search for a specific item
                                  </span>
                                </Typography>
                                <ArrowForwardIosIcon fontSize="medium" />
                              </div>
                              <ListItemText />
                            </ListItem>
                            <ListItem
                              button
                              style={{
                                padding: '10px',
                                boxShadow:
                                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                borderRadius: '10px',
                                marginTop: '20px',
                                marginBottom: '25px',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  style={{
                                    display: 'flex',
                                  }}
                                  variant="body1"
                                  onClick={() => {
                                    history.push(
                                      '/categories?type=shops&map=true',
                                    );
                                    setOpen(false);
                                  }}
                                >
                                  <LocationOnIcon />
                                  <span
                                    style={{
                                      marginLeft: '2px',
                                      marginTop: '2px',
                                    }}
                                  >
                                    {' '}
                                    Search for a seller in a certain geographic
                                    area
                                  </span>
                                </Typography>
                                <ArrowForwardIosIcon fontSize="medium" />
                              </div>
                              <ListItemText />
                            </ListItem>{' '}
                            <ListItem
                              button
                              style={{
                                padding: '10px',
                                boxShadow:
                                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                borderRadius: '10px',
                                marginTop: '20px',
                                marginBottom: '25px',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  style={{
                                    display: 'flex',
                                  }}
                                  variant="body1"
                                  onClick={() => {
                                    history.push('/seller');
                                    setOpen(false);
                                  }}
                                >
                                  {' '}
                                  <PermIdentityIcon />
                                  <span
                                    style={{
                                      marginLeft: '2px',
                                      marginTop: '2px',
                                    }}
                                  >
                                    {' '}
                                    Become a seller on our Vendor Marketplace
                                  </span>
                                </Typography>
                                <ArrowForwardIosIcon fontSize="medium" />
                              </div>
                              <ListItemText />
                            </ListItem>{' '}
                            <ListItem
                              button
                              style={{
                                padding: '10px',
                                boxShadow:
                                  '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                                borderRadius: '10px',
                                marginTop: '20px',
                                marginBottom: '25px',
                              }}
                            >
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  style={{
                                    display: 'flex',
                                  }}
                                  onClick={() => {
                                    history.push('/seller');
                                    setOpen(false);
                                  }}
                                >
                                  {' '}
                                  <PostAddIcon />{' '}
                                  <span
                                    style={{
                                      marginLeft: '2px',
                                      marginTop: '2px',
                                    }}
                                  >
                                    List an item for sale{' '}
                                  </span>
                                </Typography>
                                <ArrowForwardIosIcon fontSize="medium" />
                              </div>
                              <ListItemText />
                            </ListItem>
                          </List>
                        </div>
                      </Dialog>
                    )}
                </div>

                <Elements stripe={stripePromise}>
                  <AppRoutes />
                </Elements>
              </SnackbarProvider>
            </ProductContextProvider>
          </CategoriesContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </>
  );
}
export default App;
