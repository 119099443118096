import { privateUserConverter } from '../../models/private-user';
import { productConverter, Product } from '../../models/[new]product';
import {
  baseURL,
  generateUserToken,
  privateUsersC,
  productsC,
  auth,
} from '../../utils/firebase-utils';
import { AppThunk } from '../store';
import axios from 'axios';
import {
  CartActionTypes,
  CLEAR_CART_REQUEST,
  CLEAR_CART_SUCCESS,
  CLEAR_CART_FAIL,
  ADD_CART_ITEM_REQUEST,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_FAIL,
  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_CART_ITEM_FAIL,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_CART_ITEM_REQUEST,
  UPDATE_CART_ITEM_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS,
  GET_CART_ITEMS_FAIL,
  SET_TOTAL,
  SET_ITEM_SHIPPMENT,
  UPDATE_ITEM_SHIPPMENT,
  DELETE_ITEM_SHIPPMENT,
  RESET_CART_ERROR,
  SUBMIT_ORDER_REQUEST,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAIL,
} from '../types/cart';
import { CartItem } from '../../models/[new]product';
import { calcCart } from '../../utils/calcCart';
import { getCartProductsSelector, getProductsThunk } from './products';
import { Something_Went_Wrong } from '../../utils/constants';
import { AppState } from '../root-reducer';
import { CheckoutProcessing } from '../../components/bankAccount/checkoutService/CheckoutProcessing';

const getAddresses = (state: AppState) => {
  const {
    addresses: { addresses },
  } = state;

  const data = addresses;

  return data;
};

export const resetCartError = (): CartActionTypes => {
  return {
    type: RESET_CART_ERROR,
    payload: {},
  };
};
export const clearCartRequest = (): CartActionTypes => {
  return {
    type: CLEAR_CART_REQUEST,
    payload: {},
  };
};

export const clearCartSuccess = (): CartActionTypes => {
  return {
    type: CLEAR_CART_SUCCESS,
    payload: {},
  };
};

export const clearCartFail = (error: string): CartActionTypes => {
  return {
    type: CLEAR_CART_FAIL,
    payload: {
      error,
    },
  };
};

export const AddCartItemRequest = ({ id }: { id: string }): CartActionTypes => {
  return {
    type: ADD_CART_ITEM_REQUEST,
    payload: {
      id,
    },
  };
};

export const AddCartItemSuccess = ({
  id,
  quantity,
  price,
  isLocalPickup,
}: {
  id: string;
  quantity: number;
  price: number | null;
  isLocalPickup: boolean;
}): CartActionTypes => {
  return {
    type: ADD_CART_ITEM_SUCCESS,
    payload: {
      id,
      quantity,
      price,
      isLocalPickup,
    },
  };
};

export const AddCartItemFail = ({
  error,
  id,
}: {
  error: string;
  id: string;
}): CartActionTypes => {
  return {
    type: ADD_CART_ITEM_FAIL,
    payload: { id, error },
  };
};

export const deleteCartItemRequest = ({
  id,
}: {
  id: string;
}): CartActionTypes => {
  return {
    type: DELETE_CART_ITEM_REQUEST,
    payload: {
      id,
    },
  };
};

export const deleteCartItemSuccess = ({
  id,
  price,
}: {
  id: string;
  price: number | null;
}): CartActionTypes => {
  return {
    type: DELETE_CART_ITEM_SUCCESS,
    payload: {
      id,
      price,
    },
  };
};

export const deleteCartItemFail = ({
  error,
  id,
}: {
  error: string;
  id: string;
}): CartActionTypes => {
  return {
    type: DELETE_CART_ITEM_FAIL,
    payload: {
      error,
      id,
    },
  };
};

export const updateCartItemRequest = ({
  id,
}: {
  id: string;
}): CartActionTypes => {
  return {
    type: UPDATE_CART_ITEM_REQUEST,
    payload: {
      id,
    },
  };
};

export const updateCartItemSuccess = ({
  id,
  newQuantity,
  price,
}: {
  id: string;
  newQuantity: number;
  price: number | null;
}): CartActionTypes => {
  return {
    type: UPDATE_CART_ITEM_SUCCESS,
    payload: {
      id,
      newQuantity,
      price,
    },
  };
};
//shipping

//

export const updateCartItemFail = ({
  error,
  id,
}: {
  error: string;
  id: string;
}): CartActionTypes => {
  return {
    type: UPDATE_CART_ITEM_FAIL,
    payload: {
      error,
      id,
    },
  };
};

export const getCartItemsRequest = (): CartActionTypes => {
  return {
    type: GET_CART_ITEMS_REQUEST,
    payload: {},
  };
};
export const getCartItemsSuccess = ({
  items,
}: {
  items: CartItem[];
}): CartActionTypes => {
  return {
    type: GET_CART_ITEMS_SUCCESS,
    payload: {
      list: items,
    },
  };
};
export const getCartItemsFail = ({
  error,
}: {
  error: string;
}): CartActionTypes => {
  return {
    type: GET_CART_ITEMS_FAIL,
    payload: {
      error,
    },
  };
};

export const deleteFromCartRequest = ({
  id,
}: {
  id: string;
}): CartActionTypes => {
  return {
    type: DELETE_CART_ITEM_REQUEST,
    payload: {
      id,
    },
  };
};

export const deleteFromCartSuccess = ({
  price,
  id,
}: {
  price: number | null;
  id: string;
}): CartActionTypes => {
  return {
    type: DELETE_CART_ITEM_SUCCESS,
    payload: { price, id },
  };
};
export const deleteFromCartFail = ({
  error,
  id,
}: {
  error: string;
  id: string;
}): CartActionTypes => {
  return {
    type: DELETE_CART_ITEM_FAIL,
    payload: { error, id },
  };
};

export const setItemShippment = ({
  id,
  companyIndex,
  isLocalPickup,
}: {
  id: string;
  companyIndex: number;
  isLocalPickup: boolean;
}): CartActionTypes => {
  return {
    type: SET_ITEM_SHIPPMENT,
    payload: {
      id,
      companyIndex,
      isLocalPickup,
    },
  };
};

export const updateItemShippment = ({
  id,
  companyIndex = -1,
  isLocalPickup = false,
}: {
  id: string;
  companyIndex?: number;
  isLocalPickup?: boolean;
}): CartActionTypes => {
  return {
    type: UPDATE_ITEM_SHIPPMENT,
    payload: {
      id,
      companyIndex,
      isLocalPickup,
    },
  };
};

export const deleteItemShippment = ({
  id,
}: {
  id: string;
}): CartActionTypes => {
  return {
    type: DELETE_ITEM_SHIPPMENT,
    payload: {
      id,
    },
  };
};

export const deleteFromCartThunk = ({ id }: { id: string }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(deleteFromCartRequest({ id }));
    const {
      cart: { cart, products },
      user: { uid },
    } = getState();

    try {
      await privateUsersC
        .doc(uid)
        .withConverter(privateUserConverter)
        .set(
          {
            bag: cart.filter(product => product.id !== id),
          },
          { merge: true },
        );
      dispatch(
        deleteCartItemSuccess({
          id,
          price: products.find(pro => pro.id === id)?.price ?? 0,
        }),
      );
      dispatch(calcTotalThunk());
      await dispatch(CheckoutProcessing(uid));
    } catch (e) {
      dispatch(deleteCartItemFail({ error: e.message || e.code, id }));
    }
  };
};

export const setTotal = (total: number): CartActionTypes => {
  return {
    type: SET_TOTAL,
    payload: { total },
  };
};

export const addToCartThunk = ({
  productId,
  quantity,
  price,
}: {
  productId: string;
  quantity: number;
  price: number | null;
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(
      AddCartItemRequest({
        id: productId,
      }),
    );
    const {
      auth: { uid },
      cart: { cart },
    } = getState();

    try {
      const data = (
        await productsC.doc(productId).withConverter(productConverter).get()
      ).data() as Product;
      if (!data) {
        return dispatch(
          AddCartItemFail({ error: Something_Went_Wrong, id: productId }),
        );
      }

      if (uid === data.uid) {
        dispatch(
          AddCartItemFail({
            error: "You can't add your items to the cart.",
            id: productId,
          }),
        );
        setTimeout(() => {
          dispatch(resetCartError());
        }, 1000);
        return;
      }
      const proOnCart = cart.find(p => p.id === productId);
      let requestedQ = quantity;
      if (proOnCart?.quantity) requestedQ += proOnCart.quantity;

      const { quantity: avilableQ } = data;

      if (requestedQ > avilableQ) {
        dispatch(
          AddCartItemFail({
            error: 'The quantity is not available',
            id: productId,
          }),
        );
        setTimeout(() => {
          dispatch(resetCartError());
        }, 3000);
        return;
      }

      const { cart: newCart } = calcCart({
        type: 'ADD',
        productId,
        cart,
        quantity,
        total: 0,
        price: 0,
        isLocalPickup: data.isLocalPickup,
      });

      await privateUsersC.doc(uid).withConverter(privateUserConverter).set(
        {
          bag: newCart,
        },
        { merge: true },
      );

      await dispatch(
        AddCartItemSuccess({
          id: productId,
          quantity,
          price,
          isLocalPickup: data.isLocalPickup,
        }),
      );
      await dispatch(getProductsThunk({ ids: [productId] }));
      await dispatch(
        setItemShippment({
          id: productId,
          companyIndex: data.isLocalPickup ? -1 : 0,
          isLocalPickup: data.isLocalPickup,
        }),
      );
      await dispatch(CheckoutProcessing(uid));
      await dispatch(calcTotalThunk());
    } catch (e) {
      dispatch(AddCartItemFail({ error: Something_Went_Wrong, id: productId }));
      setTimeout(() => {
        dispatch(resetCartError());
      }, 3000);
    }
  };
};

export const getCartThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getCartItemsRequest());
    const {
      user: { uid },
    } = getState();
    try {
      const data = (
        await privateUsersC.doc(uid).withConverter(privateUserConverter).get()
      ).data();
      if (!data) {
        return dispatch(getCartItemsFail({ error: Something_Went_Wrong }));
      }
      const { bag } = data;
      await dispatch(getCartItemsSuccess({ items: bag as CartItem[] }));
      await dispatch(
        getProductsThunk({ ids: (bag as CartItem[]).map(item => item.id) }),
      );
      await dispatch(calcTotalThunk());
    } catch (e) {
      dispatch(getCartItemsFail({ error: e.message || e.code }));
    }
  };
};

export const calcTotalThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();

    const shippment = state.cart.cartShippment;

    const cartData = getCartProductsSelector(state);

    let total: number = 0;
    cartData.forEach(item => {
      const { companyIndex } = shippment.find(
        ship => ship.productId === item?.id,
      ) as {
        productId: string;
        companyIndex: number;
      };
      if (
        (item as any).shipmentCompanies.length > 0 &&
        (item as any)?.companyIndex >= 0
      ) {
        total +=
          (item as any).price * (item as any).quantity +
          (item as any).shipmentCompanies[companyIndex].price;
      } else {
        total += (item as any).price * (item as any).quantity;
      }
    });

    dispatch(setTotal(total));
  };
};

export const updateProductQThunk = ({
  id,
  newQuantity,
}: {
  id: string;
  newQuantity: number;
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(updateCartItemRequest({ id }));

    const {
      products: { list },
      auth: { uid },
      cart: { cart, total },
    } = getState();
    try {
      const { price } = list.find(product => product.id === id) as Product;
      
      // const { cart: newCart } = calcCart({
      //   type: 'UPDATE',
      //   cart,
      //   productId: id,
      //   newQuantity,
      //   price,
      //   total,
      // });
      const userDoc = await privateUsersC.withConverter(privateUserConverter).doc(uid).get();
      const userData = userDoc.data();
      
      if (!userData || !userData.bag) {
        throw new Error("Bag not found in user data");
      }
      
      const cart = userData.bag;

      const updatedCart  = cart.map((item: any) => 
        item.id === id
          ? {
              ...item,
              quantity: newQuantity,
            }
          : item,
      );
      await privateUsersC.withConverter(privateUserConverter).doc(uid).set(
        {
          bag: updatedCart,
        },
        { merge: true },
      );
      await dispatch(CheckoutProcessing(uid));
      dispatch(updateCartItemSuccess({ id, newQuantity, price }));
    } catch (e) {
      dispatch(
        updateCartItemFail({
          error: e.message || Something_Went_Wrong,
          id,
        }),
      );
    }
  };
};

// export const updateProductQThunk = ({
//   id,
//   newQuantity,
// }: {
//   id: string;
//   newQuantity: number;
// }): AppThunk => {
//   return async (dispatch, getState) => {
//     dispatch(updateCartItemRequest({ id }));

//     const {
//       products: { list },
//       auth: { uid },
//       cart: { cart, total },
//     } = getState();
//     try {
//       const { price } = list.find(product => product.id === id) as Product;
//       const { cart: newCart } = calcCart({
//         type: 'UPDATE',
//         cart,
//         productId: id,
//         newQuantity,
//         price,
//         total,
//       });

//       await privateUsersC.withConverter(privateUserConverter).doc(uid).set(
//         {
//           bag: newCart,
//         },
//         { merge: true },
//       );
//       await dispatch(CheckoutProcessing(uid));
//       dispatch(updateCartItemSuccess({ id, newQuantity, price }));
//     } catch (e) {
//       dispatch(
//         updateCartItemFail({
//           error: e.message || Something_Went_Wrong,
//           id,
//         }),
//       );
//     }
//   };
// };

export const updateProductShippmentThunk = ({
  id,
  companyIndex,
  isLocalPickup,
}: {
  id: string;
  companyIndex: number;
  isLocalPickup?: boolean;
}): AppThunk => {
  return async (dispatch, getState) => {
    const {
      user: { uid },
      cart: { cart },
    } = getState();
    try {
    
      const userDoc = await privateUsersC.withConverter(privateUserConverter).doc(uid).get();
      const userData = userDoc.data();
      
      if (!userData || !userData.bag) {
        throw new Error("Bag not found in user data");
      }
      
      const cart = userData.bag;
      
      let newCart: any = [];

      if (companyIndex >= 0 || !isLocalPickup) {
        newCart = cart.map(item =>
          item.id === id
            ? { ...item, isLocalPickup: false, companyIndex }
            : item,
        );
      } else if (isLocalPickup) {
        newCart = cart.map(item =>
          item.id === id
            ? { ...item, isLocalPickup: true, companyIndex: -1 }
            : item,
        );
      }
      await privateUsersC.withConverter(privateUserConverter).doc(uid).set(
        {
          bag: newCart,
        },
        { merge: true },
      );
      console.log(newCart, 'uuuuuu');
      await dispatch(CheckoutProcessing(uid));
      dispatch(updateItemShippment({ id, companyIndex, isLocalPickup }));
    } catch (e) {
      dispatch(
        updateCartItemFail({
          error: e.message || Something_Went_Wrong,
          id,
        }),
      );
    }
  };
};

export const submitOrderRequest = (): CartActionTypes => {
  return {
    type: SUBMIT_ORDER_REQUEST,
    payload: {},
  };
};
export const submitOrderSuccess = (): CartActionTypes => {
  return {
    type: SUBMIT_ORDER_SUCCESS,
    payload: {},
  };
};
export const submitOrderFail = ({
  error,
}: {
  error: string;
}): CartActionTypes => {
  return {
    type: SUBMIT_ORDER_FAIL,
    payload: { error },
  };
};

export const submitOrderThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(submitOrderRequest());
    const state = getState();
    const productsOnCart = getCartProductsSelector(state);

    const {
      cart: { cartShippment: shippments },
      payment: {
        cards: { list: myCards },
      },
      checkoutPage: {
        shippingAddressId,
        paymentToken,
        method,
        paymentValue,
        billingAddressId,
      },
      auth: { emailVerified, uid },
      addresses: { addresses },
    } = state;
    const cardBillingAddress = myCards.find(
      item => item.card.id === paymentToken,
    );
    const addressToSubmit =
      addresses.find(item => item.id === billingAddressId) ??
      cardBillingAddress?.billingAddress;

    let orderIsLocalPickup = true;
    const productsArray = productsOnCart.map((pro: any) => {
      const proShippment: any = shippments.find(
        productShippment => productShippment.productId === pro.id,
      );
      if (!proShippment.isLocalPickup) {
        orderIsLocalPickup = false;
      }
      const data: Record<string, any> = {
        id: pro.id,
        quantity: pro.quantity,
        isLocalPickup: !!proShippment.isLocalPickup,
        companyIndex: proShippment.companyIndex,
      };

      return data;
    });

    try {
      if (!auth?.currentUser?.emailVerified) {
        await auth?.currentUser?.reload();
        if (!auth?.currentUser?.emailVerified) {
          return auth?.currentUser
            ?.sendEmailVerification()
            .then(res => {
              return dispatch(
                submitOrderFail({
                  error: 'Please Verify Your Email to Complete',
                }),
              );
            })
            .catch(er => {
              console.log('error : ', er);
              return dispatch(
                submitOrderFail({
                  error: 'Please Verify Your Email to Complete',
                }),
              );
            });
        }
      }

      const userToken = await generateUserToken();
      if (!shippingAddressId && !orderIsLocalPickup)
        return dispatch(
          submitOrderFail({ error: 'Please select a shipping address' }),
        );
      if (!addressToSubmit)
        return dispatch(
          submitOrderFail({ error: 'Please select a billing address' }),
        );

      if (!paymentToken)
        return dispatch(
          submitOrderFail({ error: 'Please select a payment method' }),
        );

      const { id, ...rest } = addressToSubmit;
      const res = await axios.post(
        `${baseURL}/checkoutOrder`,
        {
          billingAddress: { billingAddressId: addressToSubmit.id, ...rest },
          shippingId: shippingAddressId, //todo
          products: productsArray,
          paymentData: paymentToken,
          uid: uid,
        },
        {
          headers: {
            authorization: userToken,
          },
        },
      );
      if (res.data.code === 'success') {
        // orderData
        // await axios.post(
        //   `${baseURL}/scheduleNewPayout`,
        //   {
        //     orderData: res.data.orderData,
        //   },
        //   {
        //     headers: {
        //       authorization: userToken,
        //     },
        //   },
        // );
        dispatch(submitOrderSuccess());
        dispatch(clearCartSuccess());
      }
    } catch (e) {
      console.log(e);
      dispatch(submitOrderFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};
