import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  withStyles,
  Theme,
  Menu,
  Divider,
  Typography,
  Badge,
  Button,
  MenuItem,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import { useTypedSelector } from '../../../redux/store';
import 'firebase/firestore';
import { firestore } from '../../../utils/firebase-utils';
import firebase from 'firebase/app';
// import NotificationsData from './../../../models/NotificationsData';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { IoMdNotificationsOutline } from 'react-icons/io';
import InfiniteScroll from 'react-infinite-scroll-component';
import { format, formatDistanceToNow } from 'date-fns';
import followIcon from '../../../assets/Icons/follow.png';
import commentIcon from '../../../assets/Icons/commit.png';
import likeIcon from '../../../assets/Icons/like.png';
import messageIcon from '../../../assets/Icons/message.png';
import eventIcon from '../../../assets/Icons/events.png';
import defaultIcon from '../../../assets/Icons/orders.png';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../../utils/snackbar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '50px', // button height is 48px
      maxWidth: 'initial',
      minWidth: '300px !important',
    },
    menuContainer: {
      padding: '1rem !important',
    },
    menuHead: {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '300px !important',
      alignItems: 'center',
    },
    title: {
      marginBottom: '1rem',
      fontWeight: 600,
      color: '#222529',
    },
    headDivider: {
      width: '100%',
      marginBottom: '1rem',
    },
    proItem: {
      width: '600px',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '1rem',
      borderRadius: '4px',
      padding: '0.5rem',
      cursor: 'pointer',
      '&:hover': {
        background: '#ccc',
      },
    },
    itemTitle: {
      width: '163px !important',
    },
    markedTitle: {
      color: '#9F622C',
      fontWeight: 700,
      fontSize: '10px',
    },
    loader: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '1rem',
    },
    itemPrice: {
      color: '#00000061',
    },
    listBtn: {
      color: '#574146',
      fontWeight: 'bold',
      '&:hover': {
        borderBottom: '2px solid #FDBB84',
      },
    },
  }),
);

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 8,
      top: 7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      color: 'white',
    },
  }),
)(Badge);

interface Props {
  setMobileMoreAnchorEl: (anchor: null | HTMLElement) => void;
}

interface NotificationsData {
  body: string;
  multiDevices: boolean;
  notificationId: string;
  objectId: string;
  opened: boolean;
  options: null | object;
  profilePicture: string | null;
  title: string;
  type: string;
  uid: string;
  username: string;
  createAt: firebase.firestore.Timestamp;
}

const Notifications = ({ setMobileMoreAnchorEl }: Props) => {
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { uid } = useTypedSelector(state => state.user);
  console.log(uid, 'uuuuuuid');
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] =
    useState<firebase.firestore.DocumentSnapshot | null>(null);
  const [notifications, setNotifications] = useState<NotificationsData[]>([]);
  const [counterNotifications, setCounterNotifications] = useState(0);

  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery('(max-width: 960px)');

  const toCommuintyPage = () => {
    popupState.close();
    setMobileMoreAnchorEl(null);
    history.push('/community');
  };

  const toChatPage = (objectId: string) => {
    popupState.close();
    setMobileMoreAnchorEl(null);
    history.push(`/chat/${objectId}`);
  };

  const toEventPage = (notification: any) => {
    if (notification?.type === 'normal_event_reminder') {
      history.push(`/event-details/${notification?.objectId}`);
    } else {
      history.push('/events');
    }
    popupState.close();
    setMobileMoreAnchorEl(null);
  };

  const fetchNotifications = async (isNext = false) => {
    try {
      let notificationsRef = firestore
        .collection('private_users')
        .doc(uid)
        .collection('notifications')
        .orderBy('createAt', 'desc');
        

      if (isNext && lastVisible) {
        notificationsRef = notificationsRef.startAfter(lastVisible);
      }

      const unsubscribe = notificationsRef.onSnapshot(snapshot => {
        if (snapshot.empty) {
          setHasMore(false);
          return;
        }

        const newNotifications: NotificationsData[] = snapshot.docs.map(
          doc => ({
            ...doc.data(),
            notificationId: doc.id,
          }),
        ) as NotificationsData[];

        setNotifications(prev =>
          [...newNotifications].sort((a, b) => {
            return a.opened === b.opened ? 0 : a.opened ? 1 : -1;
          }),
        );

        setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
        // If fewer than 10 notifications are fetched, set hasMore to false
        if (snapshot.docs.length < 10) {
          setHasMore(false);
        }
      });
      return unsubscribe || (() => {});
    } catch (error) {
      console.error('Error listening to notifications:', error);
    }
  };

  useEffect(() => {
    const fetchNotificationsData = async () => {
      if (uid) {
        const unsubscribe = await fetchNotifications();

        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };
      }
    };

    fetchNotificationsData();
  }, [uid]);

  useEffect(() => {
    if (uid) {
      const unsubscribe = firestore
        .collection('private_users')
        .doc(uid)
        .collection('notifications')
        .where('opened', '==', false)
        .onSnapshot(querySnapshot => {
          const unreadCount = querySnapshot.size;
          setCounterNotifications(unreadCount);
        });

      return () => unsubscribe();
    }
  }, [uid]);

  const markAllAsRead = async () => {
    try {
      const batch = firestore.batch();
      notifications.forEach(notification => {
        const docRef = firestore
          .collection('private_users')
          .doc(uid)
          .collection('notifications')
          .doc(notification?.notificationId);

        batch.update(docRef, { opened: true });
      });

      await batch.commit();

      setNotifications(prev =>
        prev
          .map(notification => ({
            ...notification,
            opened: true,
          }))
          .sort((a, b) => (a.opened === b.opened ? 0 : a.opened ? 1 : -1)),
      );
    } catch (error) {
      console.error('Error marking notifications as read:', error);
    }
  };

  const fetchOrderDetails = async (notification: any) => {
    try {
      const orderRef = await firestore
        .collection('orders')
        .doc(notification?.objectId)
        .get();

      if (orderRef.exists) {
        const orderData = orderRef.data();

        const userType = orderData?.buyerId === uid ? 'buyer' : 'seller';

        history.push(
          `/order-history/${notification?.objectId}?userType=${userType}`,
        );
        setMobileMoreAnchorEl(null);
        popupState.close();
      } else {
        console.error('Order not found');
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  const fetchProductDetails = async (notification: any) => {
    try {
      const orderRef = await firestore
        .collection('Products')
        .doc(notification?.objectId)
        .get();

      if (orderRef.exists) {
        const orderData = orderRef.data();
        const slugTitle = orderData?.slug_title;

        history.push(`/product${slugTitle}`);
        setMobileMoreAnchorEl(null);
        popupState.close();
      } else {
        console.error('product not found');
      }
    } catch (error) {
      console.error('Error fetching product details:', error);
    }
  };

  const fetchSellerDetails = async (notification: any) => {
    if (notification?.type === 'review_created') {
      history.push(`/profile/${notification?.uid}?userType=Reviews`);
    } else {
      history.push(`/profile/${notification?.uid}`);
    }
    setMobileMoreAnchorEl(null);
    popupState.close();
  };

  const toTransictionBuyerTap = () => {
    history.push('/transactions/?userType=buyer');
    popupState.close();
    setMobileMoreAnchorEl(null);
  };
  const toTransictionSellerTap = () => {
    history.push('/transactions/?userType=buyer');
    popupState.close();
    setMobileMoreAnchorEl(null);
  };

  const handleNotificationClick = async (notification: NotificationsData) => {
    try {
      const notificationRef = firestore
        .collection('private_users')
        .doc(uid)
        .collection('notifications')
        .doc(notification?.notificationId);

      await notificationRef.update({
        opened: true,
      });
      if (
        [
          'new_order',
          'status_updated',
          'order_status_changed',
          'order_cancelled',
          'request_cancel',
        ].includes(notification?.type)
      ) {
        await fetchOrderDetails(notification);
      } else if (
        [
          'payment_success',
          'new_payout',
          'refund_updated',
          'refund_processed',
        ].includes(notification?.type)
      ) {
        toTransictionBuyerTap();
      } else if (
        ['NEW_PAYOUT', 'NEW_TRANSACTION', 'PAYOUT_PROCESSED'].includes(
          notification?.type,
        )
      ) {
        toTransictionSellerTap();
      } else if (
        ['new_comment', 'new_reply', 'new_like'].includes(notification?.type)
      ) {
        toCommuintyPage();
      } else if (['product_soldout'].includes(notification?.type)) {
        await fetchProductDetails(notification);
      } else if (
        ['new_item', 'new_follow', 'review_created'].includes(
          notification?.type,
        )
      ) {
        await fetchSellerDetails(notification);
      } else if (['new_message'].includes(notification?.type)) {
        toChatPage(notification?.objectId);
      } else if (
        ['normal_event_reminder', 'live_Auction_reminder'].includes(
          notification?.type,
        )
      ) {
        toEventPage(notification);
      } else {
        console.log('Notification type is not recognized.');
      }
    } catch (error) {
      console.error('Error handling notification click:', error);
      enqueueSnackbar('Error handling notification click', {
        variant: 'error',
        ...comonSnackOpts,
      });
    }
  };

  const formatCreatedAt = (
    createAt: firebase.firestore.Timestamp | null,
  ): string => {
    if (!createAt) return 'Invalid Date';

    const createAtDate = createAt.toDate();
    const now = new Date();
    const differenceInMilliseconds = now.getTime() - createAtDate.getTime();

    const minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
    const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    if (minutes < 1) {
      return 'Just now';
    } else if (minutes < 60) {
      return `${minutes} minutes ago`;
    } else if (hours < 24) {
      return `${hours} hours ago`;
    } else if (days < 4) {
      return `${days} days ago`;
    } else {
      return format(createAtDate, 'MMM/dd/yyyy');
    }
  };

  return (
    <>
      {isSmallScreen ? (
        <MenuItem {...bindTrigger(popupState)}>
          <IconButton aria-label="show 11 new notifications" color="inherit">
            <Badge
              badgeContent={
                counterNotifications > 0 ? counterNotifications : null
              }
              color="secondary"
            >
              <IoMdNotificationsOutline />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
      ) : (
        <StyledBadge
          {...bindTrigger(popupState)}
          badgeContent={counterNotifications > 0 ? counterNotifications : null}
          color="secondary"
        >
          <IconButton>
            <img
              loading="lazy"
              src={require('../../../assets/landing-page/notification.png')}
              alt="message"
              width="24px"
              height="24px"
            />
          </IconButton>
        </StyledBadge>
      )}

      <Menu {...bindMenu(popupState)} className={classes.root}>
        <div className={classes.menuContainer}>
          <div className={classes.menuHead}>
            <Typography className={classes.title} component="p" variant="body1">
              Notifications (
              {notifications?.length === 0
                ? 'No Notifications added'
                : notifications?.length}
              )
            </Typography>
            <div
              style={{
                background: '#FFF4E9',
                padding: '8px 20px',
                marginBottom: '13px',
                borderRadius: '30px',
                cursor: 'pointer',
              }}
              onClick={markAllAsRead}
            >
              <Typography
                className={classes.markedTitle}
                component="p"
                variant="body1"
              >
                Mark all as read
              </Typography>
            </div>
          </div>
          <Divider className={classes.headDivider} />
          <InfiniteScroll
            dataLength={notifications.length}
            next={() => fetchNotifications(true)}
            hasMore={hasMore}
            height={300}
            scrollThreshold={1}
            loader={
              <div className={classes.loader}>
                <CircularProgress size={24} />
              </div>
            }
            endMessage={
              <Typography align="center" variant="body2">
                No more notifications
              </Typography>
            }
            style={{ paddingBottom: '40px' }}
          >
            <div>
              {notifications?.map(notification => (
                <div
                  key={notification?.objectId}
                  className={classes.proItem}
                  onClick={() => handleNotificationClick(notification)}
                  style={{
                    // width: xsDown ? '400px' : '600px',
                    width: '600px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: xsDown ? '8px' : '15px',
                    }}
                  >
                    <div>
                      {notification?.profilePicture ? (
                        <img
                          src={notification?.profilePicture}
                          alt="Profile"
                          style={{
                            width: '30px',
                            height: '27px',
                            borderRadius: '50%',
                          }}
                        />
                      ) : (
                        <img
                          src={(() => {
                            switch (notification?.type) {
                              case 'new_follow':
                                return followIcon;
                              case 'new_comment':
                                return commentIcon;
                              case 'new_like':
                                return likeIcon;
                              case 'new_message':
                                return messageIcon;
                              case 'normal_event_reminder':
                              case 'live_Auction_reminder':
                                return eventIcon;
                              default:
                                return defaultIcon;
                            }
                          })()}
                          alt="Default"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                      )}
                    </div>
                    <div>
                      {/* <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '15px',
                        }}
                      > */}
                      <Typography
                        className={classes.itemTitle}
                        component="p"
                        variant="body1"
                        style={{
                          color: '#191D31',
                          fontWeight: 600,
                          fontSize: '12px',
                          opacity: notification?.opened ? 0.5 : 1,
                        }}
                      >
                        {notification && notification?.title}
                        {!notification?.opened && (
                          <span
                            style={{
                              width: '8px',
                              height: '8px',
                              backgroundColor: 'green',
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginLeft: '10px',
                            }}
                          ></span>
                        )}
                      </Typography>

                      {/* <Typography variant="subtitle2" color="textSecondary">
                          {formatCreatedAt(notification?.createAt)}
                        </Typography>
                      </div> */}

                      <Typography
                        className={classes.itemPrice}
                        component="p"
                        variant="body1"
                        style={{
                          color: '#A7A9B7',
                          fontWeight: 400,
                          fontSize: '12px',
                          width: '350px',
                        }}
                      >
                        {notification && notification?.body}
                      </Typography>
                    </div>
                  </div>
                  <Typography variant="subtitle2" color="textSecondary">
                    {formatCreatedAt(notification?.createAt)}
                  </Typography>
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>
      </Menu>
    </>
  );
};

export default Notifications;
