import React, { useState, useEffect, useRef } from 'react';
import {
  alpha,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import {
  Button,
  ButtonGroup,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  withStyles,
  List,
  ListItem,
  ListItemText,
  DialogContentText,
  Box,
} from '@material-ui/core';
import Avatar from '../Avatar';
import { useTypedSelector } from '../../redux/store';
import { useHistory, useLocation } from 'react-router';
import { logoutThunk } from '../../redux/actions/auth';
import { useDispatch } from 'react-redux';
import { BiMenuAltRight } from 'react-icons/bi';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import WishlistIconMenu from './navIconsMenus/WishlistIconMenu';
import { firestore, messagingC, usersC } from '../../utils/firebase-utils';
import 'firebase/firestore';
import firebase from 'firebase/app';

import CartIconMenu from './navIconsMenus/CartIconMenu';
import { Autocomplete } from '@material-ui/lab';
import { Product } from '../../models/[new]product';
import queryString from 'query-string';
import { productsIndex } from '../../utils/algolia';
import StorefrontIcon from '@material-ui/icons/Storefront';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HomeIcon from '@material-ui/icons/Home';
import BadgeIconBtn from '../buttons/badgeIconBtn';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import MessageIcon from '@material-ui/icons/Message';
import { Link } from 'react-router-dom';
import { User } from '../../models/[new]user';
import { RiCalendarEventLine } from 'react-icons/ri';
import { BiPlanet } from 'react-icons/bi';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../utils/snackbar';
import Notifications from './navIconsMenus/Notifications';
import StoreButtons from '../Breadcrumb/StoreButtons';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: 8,
      top: 7,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      color: 'white',
    },
  }),
)(Badge);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    profile: {
      display: 'flex',
      alignItems: 'center',
      // height: "100%",
      // paddingBottom: "0px",
      // paddingTop: "0px",
      justifyContent: 'right',
      // width: "100%",
      [theme.breakpoints.down('xs')]: {
        height: '50px',
        paddingBottom: '20px',
        paddingTop: '15px',
        justifyContent: 'right',
        width: '100%',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('xs')]: {
        marginRight: '0',
      },
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },

    listBtn: {
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'center',
      color: '#FFFFFF',
      cursor: 'pointer',
      padding: '6px 16px',
      borderRadius: '40px',
      border: '1px solid #D28B53',
    },

    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },

      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchBtn: {
      borderRadius: '0rem 0.3125rem 0.3125rem 0rem',
      backgroundColor: '#FDBB84',
      height: '35px',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },

    forumBtn: {
      width: '8.875rem',
      height: '2.5rem',
      borderRadius: '5.625rem',
      background: '#B87A4E',
      color: 'white',
      fontWeight: 'bold',
      fontSize: '.9rem',
    },
    profileSwitch_avatar: {
      cursor: 'pointer',
      color: '#ccc',
      width: '30px',
      height: '30px',
    },
    form: {
      display: 'contents',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      margin: '0, 30px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    iconCommunity: {
      position: 'relative',
      right: '0',
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        right: '-25px',
      },
    },
    input: {
      backgroundColor: '#F9F9F9',
      borderRadius: '0.3125rem 0rem 0rem 0.3125rem',
      padding: theme.spacing(1, 1, 1, 1),
      transition: theme.transitions.create('width'),
      height: '35px',
      border: '1px solid #E6E6E6;',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  }),
);

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const {
    firstName,
    lastName,
    userType,
    shop,
    sellerData,
    profilePicture: { url: profilePicture },
  } = useTypedSelector(state => state.user);
  const uid = useTypedSelector(state => state.auth.uid);
  const uidUser = useTypedSelector(state => state.user.uid);
  const { enqueueSnackbar } = useSnackbar();

  const [openDialog, setOpen] = useState(false);
  const [openWelcomeDialog, setOpenWelcomeDialog] = useState(false);
  const [counter, setCounter] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'));
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const dispatch = useDispatch();
  const cart = useTypedSelector(state => state.cart.cart);
  const isSmallScreen = useMediaQuery('(max-width: 960px)');
  const isVerySmallScreen = useMediaQuery('(max-width: 650px)');

  const numOfProducts =
    cart?.length > 0
      ? cart
          .map(item => item?.quantity)
          .reduce((acc, currentValue) => acc + currentValue)
      : 0;
  const [text, setText] = useState('');
  const formRef = useRef<HTMLFormElement | null>(null);
  const [options, setOptions] = useState<Product[]>([]);
  const [redirectPage, setRedierctPage] = useState<
    'product' | 'search' | 'profile'
  >('search');

  useEffect(() => {
    if (location.pathname !== '/categories') {
      setText('');
    }
  }, [location.pathname]);

  useEffect(() => {
    (async () => {
      if (text?.trim()) {
        try {
          let result;
          result = await productsIndex.search(text, {});
          const hits: unknown[] = result?.hits;
          setOptions(
            (hits as Product[])?.filter(
              product =>
                product?.quantity !== 0 &&
                !product?.isDraft &&
                !product?.isDeleted,
            ),
          );
        } catch (e) {
          console.log(e);
        }
      }
    })();
  }, [text]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const productId = options?.find(o => o?.title === text?.trim())?.id;
    const slug_title = options?.find(
      o => o?.title === text?.trim(),
    )?.slug_title;
    if (redirectPage === 'product' && slug_title)
      history.push(`/product${slug_title}`);
    else {
      const searchQuery = queryString.stringify(
        {
          text,
        },
        { arrayFormat: 'bracket' },
      );
      history.push({
        pathname: '/categories',
        search: `?${searchQuery}`,
      });
    }
  };

  useEffect(() => {
    if (uid) {
      messagingC
        .where(`unRead.${uid}`, '>', 0)
        .orderBy(`unRead.${uid}`)
        .onSnapshot(querySnapShot => {
          if (querySnapShot.size > 0) {
            const localCounter = querySnapShot.docs
              .map(item => item.data().unRead[uid])
              .reduce((prev, next) => prev + next);
            setCounter(localCounter);
          } else {
            setCounter(0);
          }
        });
    }
  }, [uid]);

  console.log(uid, 'uid');

  const handleLogout = () => {
    dispatch(logoutThunk());
    setOpen(false);
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleCartNavigation = () => {
    if (!cart || cart.length === 0) {
      enqueueSnackbar('Your cart is empty', {
        variant: 'error',
        ...comonSnackOpts,
      });
      return;
    }
    history.push('/cart/checkout');
    setMobileMoreAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          history.push('/profile');
          setAnchorEl(null);
        }}
      >
        {userType === 'shop'
          ? 'Vendor Info'
          : userType === 'seller'
          ? 'Seller Info'
          : 'User Info'}
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/seller');
          setAnchorEl(null);
        }}
      >
        My Listings
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/order-history');
          setAnchorEl(null);
        }}
      >
        Order History
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/following');
          setAnchorEl(null);
        }}
      >
        Following
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/my-reviews');
          setAnchorEl(null);
        }}
      >
        Reviews
      </MenuItem>

      <MenuItem
        onClick={() => {
          history.push('/payment');
          setAnchorEl(null);
        }}
      >
        {/* Credit Cards & Bank Info */}
        Bank Info
      </MenuItem>

      <MenuItem
        onClick={() => {
          history.push('/transactions');
          setAnchorEl(null);
        }}
      >
        Transactions History
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/contact-us');
          setAnchorEl(null);
        }}
      >
        Contact Us
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/change-password');
          setAnchorEl(null);
        }}
      >
        Change Password
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpen(true);
          setAnchorEl(null);
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={() => {
          history.push('/');
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge color="secondary">
            <HomeOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Home</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/community');
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge color="secondary">
            <LanguageOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Community</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/events');
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge color="secondary">
            <RiCalendarEventLine />
          </Badge>
        </IconButton>
        <p>Events</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/blogs');
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge color="secondary">
            <BiPlanet />
          </Badge>
        </IconButton>
        <p>Blogs</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          setOpenWelcomeDialog(true);
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge color="secondary">
            <FormatListBulletedIcon />
          </Badge>
        </IconButton>
        <p>Welcome Menu</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/categories?type=shops');
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge color="secondary">
            <StorefrontIcon />
          </Badge>
        </IconButton>
        <p>Sellers</p>
      </MenuItem>
      <MenuItem
        onClick={() => {
          history.push('/categories-collection');
          setMobileMoreAnchorEl(null);
        }}
      >
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge color="secondary">
            <CategoryOutlinedIcon />
          </Badge>
        </IconButton>
        <p>Categories</p>
      </MenuItem>
      {uid ? (
        <>
          <MenuItem
            onClick={() => {
              history.push('/chat');
              setMobileMoreAnchorEl(null);
            }}
          >
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge
                badgeContent={counter > 0 ? counter : null}
                color="secondary"
              >
                <ChatOutlinedIcon />
              </Badge>
            </IconButton>
            <p>Message</p>
          </MenuItem>
          <MenuItem onClick={handleCartNavigation}>
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge badgeContent={numOfProducts ?? ''} color="secondary">
                <ShoppingCartOutlinedIcon />
              </Badge>
            </IconButton>
            <p>Cart</p>
          </MenuItem>
          <WishlistIconMenu setMobileMoreAnchorEl={setMobileMoreAnchorEl} />
          <Notifications setMobileMoreAnchorEl={setMobileMoreAnchorEl} />
          {/* <MenuItem
            onClick={() => {
              // history.push('/chat');
              setMobileMoreAnchorEl(null);
            }}
          >
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge
                badgeContent={
                  counterNotifications > 0 ? counterNotifications : null
                }
                color="secondary"
              >
                <ChatOutlinedIcon />
              </Badge>
            </IconButton>
            <p>Notifications</p>
          </MenuItem> */}

          <MenuItem
            onClick={() => {
              history.push('/seller');
              setMobileMoreAnchorEl(null);
            }}
          >
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge color="secondary">
                <LocalOfferOutlinedIcon />
              </Badge>
            </IconButton>
            {/* <p>Seller list</p> */}
            <p>My listings</p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push('/seller');
              setMobileMoreAnchorEl(null);
            }}
          >
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge color="secondary">
                <FormatListBulletedIcon />
              </Badge>
            </IconButton>
            <p>list Item</p>
          </MenuItem>
        </>
      ) : (
        <>
          <MenuItem
            onClick={() => {
              history.push('/signup');
              setMobileMoreAnchorEl(null);
            }}
          >
            <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge color="secondary">
                <ExitToAppOutlinedIcon />
              </Badge>
            </IconButton>
            <p>Sign up</p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push('/login');
              setMobileMoreAnchorEl(null);
            }}
          >
            <IconButton aria-label="show 11 new notifications" color="inherit">
              <Badge color="secondary">
                <ExitToAppOutlinedIcon />
              </Badge>
            </IconButton>
            <p>Log in</p>
          </MenuItem>
        </>
      )}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ backgroundColor: 'white' }}>
        <Toolbar className={classes.header}>
          <Box display="flex" alignItems="center">
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => history.push('/')}
            >
              <img
                src={require('../../assets/landing-page/logo3.png')}
                alt="logo"
                style={{
                  // width: isSmallScreen ? '60px' : '95px',
                  width: isSmallScreen ? '60px' : '75px',
                  height: isSmallScreen ? '50px' : '60px',
                  // height: isSmallScreen ? '40px' : '60px',
                }}
              />
            </IconButton>
            <div
              className={classes.search}
              style={{ marginLeft: isSmallScreen ? '10px' : '20px' }}
            >
              <ButtonGroup
                disableElevation
                variant="contained"
                color="primary"
                style={{ height: '35px' }}
              >
                <form
                  ref={formRef}
                  className={classes.form}
                  onSubmit={handleSubmit}
                  style={{ width: isSmallScreen ? '70vw' : '350px' }}
                >
                  <Autocomplete
                    value={text}
                    freeSolo
                    onInputChange={async (e, value) => {
                      await setText(value);
                      setRedierctPage('search');
                    }}
                    onChange={async (e, value) => {
                      await setText(value);
                      setRedierctPage('product');
                      formRef.current?.dispatchEvent(new Event('submit'));
                    }}
                    disableClearable
                    options={options?.map(option => option?.title)}
                    renderInput={params => (
                      <div
                        ref={params.InputProps.ref}
                        style={{ width: isVerySmallScreen ? '140px' : '350px' }}
                      >
                        <input
                          {...params.inputProps}
                          className={classes.input}
                          placeholder="Search"
                          type="search"
                        />
                      </div>
                    )}
                  />
                  <Button className={classes.searchBtn} type="submit">
                    <SearchIcon />
                  </Button>
                </form>
              </ButtonGroup>
            </div>
          </Box>

          <Box className={classes.profile}>
            {isSmallScreen && (
              <Button
                className={classes.listBtn}
                style={{
                  color: '#D28B53',
                  backgroundColor: '#fff',
                  textTransform: 'capitalize',
                  marginRight: '10px',
                  marginLeft: '10px',
                  padding: '6px 27px',
                }}
                onClick={() => history.push('/categories?type=shops')}
              >
                Sellers
              </Button>
            )}
            {uid && isSmallScreen && (
              <Badge>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                >
                  <Avatar
                    className={classes.profileSwitch_avatar}
                    firstName={
                      userType === 'shop'
                        ? shop?.shopName
                        : userType === 'seller'
                        ? sellerData?.sellerName
                        : firstName
                    }
                    lastName={userType === 'buyer' ? lastName : ''}
                    src={userType === 'shop' ? shop?.shopLogo : profilePicture}
                    style={{
                      display: 'flex',
                    }}
                  />
                </IconButton>
              </Badge>
            )}
            {!uid && isSmallScreen && (
              <Button
                onClick={() => history.push('/community')}
                className={classes.iconCommunity}
              >
                <LanguageOutlinedIcon />
              </Button>
            )}
            {isSmallScreen && (
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
              >
                <BiMenuAltRight style={{ color: '#574146' }} />
              </IconButton>
            )}
          </Box>

          {!isSmallScreen && (
            <Box display="flex" alignItems="center">
              <IconButton
                style={{
                  paddingRight: '5px',
                }}
              >
                <BadgeIconBtn
                  content={counter > 0 ? null : null}
                  tooltipTitle="Welcome Menu"
                >
                  <FormatListBulletedIcon
                    onClick={() => setOpenWelcomeDialog(true)}
                  />
                </BadgeIconBtn>
              </IconButton>
              <IconButton
                style={{
                  paddingBottom: '5px',
                }}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.zetaton.antiquesmart"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    loading="lazy"
                    src={require('../../assets/landing-page/Like (1).png')}
                    alt="get on google play"
                    width="30px"
                    height="30px"
                  />
                </a>
              </IconButton>
              <IconButton
                style={{
                  paddingBottom: '5px',
                }}
              >
                <a
                  href="https://apps.apple.com/us/app/antiquesmart/id1526581604?platform=iphone"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    loading="lazy"
                    src={require('../../assets/landing-page/apple.png')}
                    alt="get on app store"
                    width="30px"
                    height="30px"
                  />
                </a>
              </IconButton>
              {uid ? (
                <>
                  <Badge
                    badgeContent={counter > 0 ? counter : null}
                    color="secondary"
                  >
                    <IconButton onClick={() => history.push('/chat')}>
                      <img
                        loading="lazy"
                        src={require('../../assets/landing-page/messageLanding.png')}
                        alt="message"
                        width="24px"
                        height="24px"
                      />
                    </IconButton>
                  </Badge>
                  <CartIconMenu />
                  <WishlistIconMenu
                    setMobileMoreAnchorEl={setMobileMoreAnchorEl}
                  />
                  <Notifications
                    setMobileMoreAnchorEl={setMobileMoreAnchorEl}
                  />

                  <Badge>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                    >
                      <Avatar
                        className={classes.profileSwitch_avatar}
                        firstName={
                          userType === 'shop'
                            ? shop?.shopName
                            : userType === 'seller'
                            ? sellerData?.sellerName
                            : firstName
                        }
                        lastName={userType === 'buyer' ? lastName : ''}
                        src={
                          userType === 'shop' ? shop?.shopLogo : profilePicture
                        }
                        style={{
                          display: 'flex',
                        }}
                      />
                    </IconButton>
                  </Badge>
                </>
              ) : (
                <>
                  <Button
                    className={classes.listBtn}
                    style={{
                      color: '#D28B53',
                      backgroundColor: '#fff',
                      textTransform: 'capitalize',
                      marginRight: '10px',
                      padding: '6px 27px',
                    }}
                    onClick={() => history.push('/login')}
                  >
                    Log in
                  </Button>
                  <Button
                    className={classes.listBtn}
                    style={{
                      color: '#fff',
                      backgroundColor: '#D28B53',
                      textTransform: 'capitalize',
                      padding: '6px 27px',
                    }}
                    onClick={() => history.push('/signup')}
                  >
                    Sign Up
                  </Button>
                </>
              )}
            </Box>
          )}
        </Toolbar>
        {!isSmallScreen && (
          <div
            className={classes.sectionDesktop}
            style={{
              backgroundColor: '#D28B53',
              display: 'flex',
              alignItems: 'center',
              padding: '6px 30px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: 'auto',
              }}
            >
              <Typography
                className={classes.listBtn}
                onClick={() => history.push('/')}
              >
                Home
              </Typography>
              <Typography
                className={classes.listBtn}
                onClick={() => history.push('/categories-collection')}
              >
                Categories
              </Typography>
              <Typography
                className={classes.listBtn}
                onClick={() => history.push('/categories?type=shops')}
              >
                Sellers
              </Typography>
              {uid && (
                <Typography
                  className={classes.listBtn}
                  onClick={() => history.push('/seller')}
                >
                  {/* Seller list */}
                  My listings
                </Typography>
              )}

              <Typography
                className={classes.listBtn}
                onClick={() => history.push('/community')}
              >
                {/* <LanguageOutlinedIcon /> */}
                Community
              </Typography>
              <Typography
                className={classes.listBtn}
                onClick={() => history.push('/events')}
              >
                Events
              </Typography>
              <Typography
                className={classes.listBtn}
                onClick={() => history.push('/blogs')}
              >
                Blogs
              </Typography>
            </div>
            {uid && (
              <div
                style={{
                  backgroundColor: '#fff',
                  color: '#D28B53',
                  padding: '6px 16px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #D28B53',
                  borderRadius: '40px',
                }}
                onClick={() => history.push('/seller')}
              >
                <FormatListBulletedIcon
                  style={{
                    color: '#D28B53',
                  }}
                />
                <Typography
                  className={classes.listBtn}
                  style={{
                    padding: '0px 4px',
                    color: '#D28B53',
                    border: 'none',
                  }}
                >
                  List item
                </Typography>
              </div>
            )}
          </div>
        )}
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openDialog}
        onClose={() => setOpen(false)}
      >
        <DialogTitle id="id" style={{ padding: '30px' }}>
          <Typography
            style={{ color: '#663C00' }}
            component="p"
            variant="subtitle1"
          >
            Would you like to logout?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={12}>
              <div
                style={{
                  width: '100%',
                  padding: '20px',
                  paddingRight: '0',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  onClick={() => {
                    setOpen(false);
                  }}
                  style={{ marginRight: '20px' }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleLogout();
                  }}
                  style={{ color: 'white' }}
                >
                  Logout
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {openWelcomeDialog && (
        <Dialog
          open={openWelcomeDialog}
          aria-labelledby="responsive-dialog-title"
          onClose={() => setOpenWelcomeDialog(false)}
        >
          <div style={{ padding: '20px' }}>
            <Grid
              lg={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px',
              }}
            >
              <img
                src={require('../../assets/newlogo.png')}
                alt="logo"
                style={{ width: '220px', height: '170px' }}
              />
            </Grid>
            <Box
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <StoreButtons />
            </Box>
            <DialogTitle
              id="responsive-dialog-title"
              style={{ paddingBottom: '0px' }}
            >
              {' Welcome to Antiquesmart.com !'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText style={{ fontWeight: 'bold' }}>
                "The new way to buy and sell old things!" <br />
                <br />
                <span>What would you like to do Today ?</span>
                <br />
              </DialogContentText>
            </DialogContent>
            <div style={{ borderTop: '1px solid #DDDDDD' }} />
            <List style={{ marginBottom: '20px' }}>
              <ListItem
                button
                style={{
                  padding: '10px',
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  borderRadius: '10px',
                  marginTop: '20px',
                  marginBottom: '25px',
                }}
                onClick={() => {
                  history.push('/categories?type=shops');
                  setOpenWelcomeDialog(false);
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="body1" style={{ display: 'flex' }}>
                    <HomeIcon />
                    <span
                      style={{
                        marginLeft: '2px',
                        marginTop: '2px',
                      }}
                    >
                      Browse our Vendor Marketplace
                    </span>
                  </Typography>
                  <ArrowForwardIosIcon fontSize="medium" />
                </div>
                <ListItemText />
              </ListItem>
              <ListItem
                button
                style={{
                  padding: '10px',
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  borderRadius: '10px',
                  marginTop: '20px',
                  marginBottom: '25px',
                }}
                onClick={() => {
                  history.push('/categories?text=');
                  setOpenWelcomeDialog(false);
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <SearchIcon />
                    <span
                      style={{
                        marginLeft: '2px',
                        marginTop: '2px',
                      }}
                    >
                      Search for a specific item
                    </span>
                  </Typography>
                  <ArrowForwardIosIcon fontSize="medium" />
                </div>
                <ListItemText />
              </ListItem>
              <ListItem
                button
                style={{
                  padding: '10px',
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  borderRadius: '10px',
                  marginTop: '20px',
                  marginBottom: '25px',
                }}
                onClick={() => {
                  history.push('/categories?type=shops&map=true');
                  setOpenWelcomeDialog(false);
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      display: 'flex',
                    }}
                    variant="body1"
                  >
                    <LocationOnIcon />
                    <span
                      style={{
                        marginLeft: '2px',
                        marginTop: '2px',
                      }}
                    >
                      Search for a seller in a certain geographic area
                    </span>
                  </Typography>
                  <ArrowForwardIosIcon fontSize="medium" />
                </div>
                <ListItemText />
              </ListItem>
              <ListItem
                button
                style={{
                  padding: '10px',
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  borderRadius: '10px',
                  marginTop: '20px',
                  marginBottom: '25px',
                }}
                onClick={() => {
                  history.push('/seller');
                  setOpenWelcomeDialog(false);
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    style={{
                      display: 'flex',
                    }}
                    variant="body1"
                  >
                    <PermIdentityIcon />
                    <span
                      style={{
                        marginLeft: '2px',
                        marginTop: '2px',
                      }}
                    >
                      Become a seller on our Vendor Marketplace
                    </span>
                  </Typography>
                  <ArrowForwardIosIcon fontSize="medium" />
                </div>
                <ListItemText />
              </ListItem>
              <ListItem
                button
                style={{
                  padding: '10px',
                  boxShadow:
                    '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  borderRadius: '10px',
                  marginTop: '20px',
                  marginBottom: '25px',
                }}
                onClick={() => {
                  history.push('/seller');
                  setOpenWelcomeDialog(false);
                }}
              >
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      display: 'flex',
                    }}
                  >
                    <PostAddIcon />
                    <span
                      style={{
                        marginLeft: '2px',
                        marginTop: '2px',
                      }}
                    >
                      List an item for sale
                    </span>
                  </Typography>
                  <ArrowForwardIosIcon fontSize="medium" />
                </div>
                <ListItemText />
              </ListItem>
            </List>
          </div>
        </Dialog>
      )}
    </div>
  );
}
