import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  makeStyles,
  createStyles,
  Typography,
  Box,
  Button,
  Theme,
} from '@material-ui/core';
import successOrder from '../assets/success-order.png';
import { useTypedSelector } from '../redux/store';
import { firestore } from '../utils/firebase-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '50%',
      padding: '24px',
      margin: '3rem auto 1rem auto',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    successOrderImg: {
      marginBottom: '25px',
    },
    icon: {
      fontSize: '48px',
      color: '#FFA500',
      marginBottom: '16px',
    },
    orderNumber: {
      color: '#FFA500',
      fontWeight: 600,
      fontSize: '22px',
    },
    buttonContainer: {
      width: '100%',
      padding: '20px',
      display: 'flex',
      justifyContent: 'center',
      gap: '25px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },

    customBtn: {
      color: '#D28B53',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      borderRadius: '10px',
      fontSize: '16px',
      padding: '12px 30px !important',
      '&.MuiButton-textSizeLarge': {
        padding: '12px 30px !important',
      },
    },
    customButton: {
      color: 'white',
      fontWeight: 'bold',
      background: '#D28B53',
      textTransform: 'capitalize',
      borderRadius: '10px',
      padding: '13px 28px !important',
      '&.MuiButton-textSizeLarge': {
        padding: '13px 28px !important',
      },
      '&:hover': {
        background: '#D28B53',
      },
    },
  }),
);

const SuccessOrder: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const uid = useTypedSelector(state => state.auth.uid);
  const [orderUIId, setOrderUIId] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchOrder = async () => {
      try {
        const ordersRef = firestore.collection('orders');
        const querySnapshot = await ordersRef
          .where('buyerId', '==', uid)
          .orderBy('createdAt', 'desc')
          .limit(1)
          .get();

        if (!querySnapshot.empty) {
          const orderData = querySnapshot.docs[0].data();
          console.log(orderData, 'orderData')
          setOrderUIId(orderData?.orderUIId);
        }
      } catch (error) {
        console.error('Error fetching order:', error);
      }
    };

    fetchOrder();
  }, [uid]);
  console.log(orderUIId, 'orderUIId')
  return (
    <Box className={classes.root}>
      <img
        loading="lazy"
        className={classes.successOrderImg}
        src={successOrder}
        alt="successOrder"
      />
      <Typography
        variant="h5"
        gutterBottom
        style={{
          fontSize: '25px',
          color: '#171717',
          fontWeight: 600,
          marginBottom: '15px',
        }}
      >
        Order Successful!
      </Typography>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          variant="body1"
          style={{
            color: '#5A5A5A',
            fontWeight: '500',
            fontSize: '16px',
            marginRight: '5px',
          }}
        >
          Order number:
        </Typography>
        <Typography className={classes.orderNumber}>
          {orderUIId || 'Loading...'}
        </Typography>
      </div>
      <Typography
        variant="body2"
        style={{
          color: '#5A5A5A',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '30px',
          marginBottom: '15px',
        }}
      >
        Thank you for choosing us! You will receive a confirmation email shortly
        with all the details.
      </Typography>
      <Box className={classes.buttonContainer}>
        <Button
          variant="outlined"
          size="large"
          onClick={() => history.push('/order-history')}
          className={classes.customBtn}
        >
          View Order Details
        </Button>
        <Button
          size="large"
          onClick={() => history.push('/categories?text=')}
          className={classes.customButton}
        >
          Continue Shopping
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessOrder;
